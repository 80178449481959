/* ----- Fonts ----- */

/* Style de base */
body {
    @apply font-body overflow-x-hidden;
}

h1 {
    @apply font-heading;
}
h2 {

}
h3 {

}

img {
    @apply inline-block;
}
a {
    @apply transition-all;
}
main {
    & p {
        @apply mb-2;
    }
    a {
        @apply text-primary-500 hover:text-primary-700;
    }
}
header li:not(.header-logo) a ~ ul a {
    @apply text-primary lg:text-secondary;
}
/* Menu et sous-menu */
@screen lg {
    nav ul li:not(.header-logo) {
        & > ul {
            @apply absolute x-center top-full flex flex-wrap justify-between w-screen mt-4 lg:px-24 xl:px-40 pb-8 bg-white border-t-2 border-primary opacity-0 invisible overflow-hidden transition-all z-10;
            & li {
                @apply w-30p border-none mt-4;

                & a {
                    & .group::before {
                        content: '';
                        @apply absolute left-0 top-0 w-full h-full bg-primary bg-opacity-75 opacity-0 transition-all;
                    }
                    &:hover .group::before {
                        @apply opacity-100;
                    }
                }

                & img {
                    @apply h-full max-h-40;
                }
            }
        }
        &:hover > ul {
            @apply opacity-100 visible;
        }
    }
}
@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */

.transitioning {
    transition: .3s ease all;
}

/* Site CSS */

/* Global */
.fil-ariane {
    @apply flex items-center flex-wrap text-sm text-secondary-450 mb-12;

    & a {
        @apply text-secondary-450 hover:text-primary;
    }

    & span {
        @apply mx-1;
    }

    & p {
        @apply font-bold mb-0;
    }
}
h1 {
    @apply relative text-3xl text-secondary-400;
}

html {
    @apply font-body;
}

h2 {
    @apply relative font-heading text-2xl lg:text-3xl xl:text-5xl mb-8 lg:mb-12;
}

a {
    @apply inline-block text-primary-500;
}

a:hover {
    @apply text-black;
}

.bouton, .btn, input[type=submit] {
    @apply relative inline-block px-4 xl:px-6 py-1.5 lg:py-2 bg-primary rounded-full text-white cursor-pointer;
}

.bouton:hover, .btn:hover, input[type=submit]:hover {
    @apply bg-secondary-500 text-white;
}

.bords {
    @apply border border-solid border-grey-300 p-4;
}

.swiper {
    & .swiper-slide > article {
        @apply !w-full;
    }
}

/* Colorbox */
.colorbox {
    box-shadow: 0px 0px 8px #333;
}

/* Formulaire */
input[type=text], input[type=email], input[type=password], input[type=tel], select, textarea {
    @apply border-2 border-solid border-grey-200 p-1 rounded-lg bg-white;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/img/icones/picto-arrow-down.svg');
    background-size: 10px auto;
    background-repeat: no-repeat;
    background-position: bottom 46% right 5px;
    padding-right: 1.5rem;
}

select.decorated option:hover {
    box-shadow: 0 0 10px 100px #d6d440 inset;
}

.form {
    @apply relative block;
}

.form input[type=text] + label, .form input[type=email] + label, .form input[type=tel] + label, .form input[type=password] + label {
    position: absolute;
    left: 3%;
    top: 1.25rem;
    pointer-events: none;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 200ms ease;
    color: #9a9a9a;
    text-align: left;
}

.form input[type=text]:focus + label, .form input[type=email]:focus + label, .form input[type=tel]:focus + label, .form input[type=password]:focus + label, .form textarea:focus ~ label, .form label.freeze {
    @apply text-secondary-500 font-normal text-xs !important;
    transform: translateY(-1.5rem) !important;
}

/* Cadres */
.box-ligne {
    @apply relative border-b border-solid border-grey-300;
}

.card {
    @apply bg-white shadow-xl mb-4 py-8 px-12;
}

/* Header */
header {
    @apply relative text-center bg-white z-10 transitioning shadow-sm;

    & nav {
        position: initial;
    }

}

header ul, header ul li {
    @apply list-none p-0 m-0;
}

header li:not(.header-logo) {
    @apply flex-grow;

    & a {
        @apply text-lg text-secondary hover:font-bold hover:text-primary;
    }

    &.nav-active a {
        @apply font-bold text-primary;
    }
}


header ul.subnav {
    @apply bg-white;
}

header ul.subnav li a {
    @apply block bg-blue;
}

header ul.subnav li a:hover {
    @apply bg-white text-blue;
}

#lang-select {
    &::after {
        content: '';
        @apply absolute -right-2 top-3 w-3 h-3 opacity-0 transition-all transform rotate-90 z-10 cursor-pointer;
        background: url("/img/icones/chevron-right.svg") no-repeat;
        background-size: contain;
    }

    &:hover::after, &.active::after {
        @apply opacity-100;
    }
}

.is-sticky header {
    @apply shadow-lg;
}

/*
header.scrollingdown nav {
    @apply py-0 transitioning;
}

header.scrollingdown .logo img {
    @apply h-8 lg:h-12 lg:mx-auto transitioning;
}
*/


/* Contenu */
#main {
    @apply relative;
}

#content {
    @apply py-8 lg:py-20;

    h1 {
        @apply text-3xl xl:text-6xl mb-5 text-secondary;
    }

    h2 {
        @apply font-headingsingle text-2xl xl:text-4xl mb-3 mt-8 text-primary;
    }

    & ul {
        @apply list-disc list-inside mb-4;
    }

    a {
        @apply text-primary font-bold hover:underline;
    }

    @screen lg {
        &:before {
            content: '';
            width: 400px;
            height: 100%;
            position: absolute;
            top: 0;
            left: -50px;
            background-image: url("/img/motif-trajet-vertical.png");
            background-position: bottom center;
            background-size: 100% auto;
            background-repeat: no-repeat;
        }
    }
}

/* Pré-Footer */
#pre-footer {
    background-image: url("/img/photos/AdobeStock_224381552_Preview.jpg");
    background-size: cover;

    & h2 {
        @apply text-xl xl:text-4xl mb-8;
    }

    & p {
        @apply xl:text-xl;
    }
}

/* Footer */
footer {
    @apply bg-secondary text-white;

    h2 {
        @apply font-body font-bold text-xl mb-0
    }

    h3 {
        @apply font-bold mb-1.5
    }

    a {
        @apply text-white;
    }

    a:focus,
    a:hover {
        @apply text-primary;
    }

    img {
        width: auto;
        height: 50px;
        @apply inline-block py-4;
    }

    background-image: url("/img/motif-trajet-dark.png");
    background-position: bottom center;
    background-size: 100% auto;
    background-repeat: no-repeat;

}

/*Aside */
aside {
    @apply fixed right-4 lg:right-6 z-40;
    bottom: 17%;
}

@screen xl {
    aside {
        top: 17%;
        bottom: auto;
    }
}

/*Containers*/
.basic-container {
    @apply w-80p lg:w-90p mx-auto md:max-w-1300 relative;
}

.small-container {
    @apply w-80p lg:w-90p mx-auto md:max-w-900 relative;
}

/*Swiper accueil */
.swiper-hero {
    /* pour conserver les proportions*/
    height: 30vw;
    min-height: 240px;

    .dark-layer {
        & p {
            @apply text-white text-xl xxs:text-2xl lg:text-5xl xl:text-6xl font-headingsingle leading-none mb-0;

            & strong {
                @apply font-heading font-normal lg:text-5xl xl:text-7xl;
            }
        }
    }
}

/*calque noir transparent appliqué sur des images de fond*/
.dark-layer {
    @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full;
    background-color: rgba(0, 0, 0, 0.25);
}

/*Swiper pagination*/
.swiper-pagination-bullet {
    position: relative;
    background-color: white;
    opacity: 0.60;
    width: 10px;
    height: 10px;
    z-index: 0;
}

@screen lg {
    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
    }
}

.swiper-container-vertical > .swiper-pagination-bullets {
    right: 20px;
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 14px 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 9px;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    @apply relative;
    margin: 19px 0;
}

.swiper-pagination-bullet-active:after {
    content: " ";
    @apply absolute abs-center rounded-full border border-white;
    width: 165%;
    height: 165%;

}

#slider-vehicules {
    & .swiper-pagination {
        &-bullet {
            @apply bg-primary;

            &-active::before {
                content: '';
                @apply absolute abs-center w-5 h-5 border border-primary rounded-full;
            }
        }
    }
}

@screen lg {
    #nos-vehicules {
        background-image: url("/img/motif-trajet.png");
        background-position: bottom center;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
}

@screen lg {
    #search-section {
        transform: translateY(-50%);
    }
}

#hero {
    @apply relative text-white py-12 xl:py-24 z-1;

    & div {
        @apply basic-container relative text-white z-1;

        & h1 {
            @apply text-white text-4xl sm:text-5xl lg:text-6xl mb-2;
        }

        & p {
            @apply w-full lg:w-4/5 xl:w-3/5 xxl:w-1/2;
        }
    }

    & img {
        @apply absolute left-0 top-0 w-full h-full object-cover -z-1;
    }

    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-secondary bg-opacity-75;
    }
}

#filter-categorie {
    & .categorie {
        @apply block mb-8 mr-4;
        & input {
            @apply absolute h-0 w-0 opacity-0 invisible;

            &:checked ~ label {
                @apply bg-primary text-white;
            }
        }

        & label {
            @apply pointer-events-none font-bold border border-primary hover:bg-primary hover:bg-opacity-25 py-2 px-8 rounded-full cursor-pointer transition-all;
        }
    }

}

#slider-gallery-thumbs {
    & .swiper-slide {
        @apply border-4 border-transparent cursor-pointer;
        &-active {
            @apply border-primary;
        }
    }
}

.home-text a {
    @apply btn btn-outline mt-4 rounded-full text-base;
    background-color: transparent !important;
    color: white!important;
}
.home-img img {
    @apply hidden lg:block lg:absolute top-0 bottom-0 left-0 h-full lg:w-1/3 xl:w-45p object-cover;
}